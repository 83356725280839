import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/global/Layout";
import SectionParagraph from "../../components/ui/SectionParagraph";
import SectionTitle from "../../components/ui/SectionTitle";

const ZlomElektronikiPage = () => {
  return (
    <Layout
      titleTemplate="%s"
      pageTitle="Skup złomu elektorniki, procesory, płyty, dyski twarde i wiele innych"
      pageDescription="Skup złomu elektroniki. Skup działającego sprzętu i złomu elektronicznego."
    >
      <section className="container mx-auto px-5 py-16">
        <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap space-x-8">
          <div className="md:w-1/2">
            <StaticImage
              src="../../images/services/elektronika.jpg"
              alt="Złom elektroniki"
              width={800}
              height={600}
            />
          </div>
          <div className="md:w-1/2">
            <SectionTitle size="small">Złom elektroniki</SectionTitle>
            <SectionParagraph className="mb-5">
              <strong>Skupujemy:</strong>
            </SectionParagraph>
            <div className="flex flex-row gap-6 justify-between items-center flex-wrap lg:flex-nowrap">
              <div className="md:w-1/2">
                <ul className="list-disc text-xl text-gray-700 pl-5">
                  <li>procesory</li>
                  <li>płyty główne</li>
                  <li>płyty serwerowe</li>
                  <li>płyty laptopowe</li>
                  <li>płytki klasy B</li>
                  <li>płytki klasy C</li>
                  <li>karty graficzne</li>
                  <li>karty muzyczne</li>
                  <li>karty rozszerzeń</li>
                  <li>płyty z tabletów</li>
                  <li>płytki z HDD</li>
                  <li>płytki z napędów</li>
                  <li>płytki z telefonów komórkowych</li>
                  <li>płytki ze smartfonów</li>
                  <li>płytki z paneli LCD</li>
                </ul>
              </div>
              <div className="md:w-1/2">
                <ul className="list-disc text-xl text-gray-700 pl-5">
                  <li>laminaty</li>
                  <li>laminat ze złoceniami</li>
                  <li>pamięci RAM </li>
                  <li>napędy</li>
                  <li>dyski twarde</li>
                  <li>zasilacze</li>
                  <li>laptopy</li>
                  <li>telefony komórkowe</li>
                  <li>tablety</li>
                  <li>płyty CD DVD</li>
                  <li>komputery samochodowe</li>
                  <li>układy scalone</li>
                  <li>silniki elektryczne</li>
                  <li>piny złocone</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ZlomElektronikiPage;
